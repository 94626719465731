@import "./variables.scss";
:root {
  --font-family: 'fs_eliot';
  --second-family:  'goodtimes';
  --third-family: 'Atom-Regular';
  --font3: 'Atom-Regular';
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
body
{
color:var(--primary-color);
background-color:var(--bg-color);
}
.body_animate
{
transition:0.3s background-color linear;
}
.body_animate DIV
{
transition:0.3s color linear;
}
.cursor_move
{
cursor:move;
}
#root
{
width:min(var(--maxwidth-portal),100%);
margin:0 auto;
/*
position:relative;
left:50%;
top:0px;
transform: translateX(-50%);
width:100%;
max-width:2560px;*/
/*
display:flex;
flex-direction:column;
align-items:center;
*/
}