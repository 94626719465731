/*@import "./variables.scss";*/
@keyframes carAnim {
    0% {
        left:-20%;
     top:0%;
    }
    100% {
left:0%;
top:42%;
    }
}
.button
{
display:flex;
justify-content:center;
align-items:center;
font-family: "Atom_i_demibold";
font-size: 16px;
color: #fff;
background:var(--button-gradient);
border-radius:20px;
margin:0 auto;
width: calc(100% - 24px - 24px);
height: 40px;
padding-left:10px;
padding-right:10px;
filter:brightness(1);
transition:0.3s all linear;
}
.button:active
{
background:var(--button-gradient-pressed);
}
.button:HOVER
{
filter:brightness(1.1);
}
/*main header*/
.main
{
position:relative;
margin-top:88px;
line-height:1.2;
width:100%;
max-width:var(--maxwidth-portal);
height:calc(100vh - 88px);
min-height:600px;
	.main_top_title
	{
position:absolute;
left:56px;
top:122px;
font-family: 'Atom-Bold';
font-size:32px;
max-width:468px;
		SPAN {color: var(--accent-color)}
	}
	.bottom_popup
	{
display:flex;
flex-direction:column;
/*justify-content:center;*/
position:absolute;
right:224px;
bottom:62px; /*64*/
width:231px; /*231*/
height:163px;
/*margin-top:8px;*/
padding-left:21px;
padding-right:0px /*11*/;
background-repeat:no-repeat;
		.bottom_popup_title
		{
font-family:  'Atom-Medium';
font-size: 20px;
margin-top:34px; /*37*/
max-width:200px;
animation-name:fadeIn;
animation-iteration-count:1;
animation-timing-function:linear;
animation-duration:0.3s;
animation-fill-mode:forwards;
		}
		.bottom_popup_text
		{
font-family:  'Atom_i';
font-size: 12px; /*11 12*/
margin-top:9px; /*8*/
max-width:200px;
animation-name:fadeIn;
animation-iteration-count:1;
animation-timing-function:linear;
animation-duration:0.3s;
animation-fill-mode:forwards;
		}
		.bottom_popup_bottom
		{
display:flex;
align-items:center;
margin-top:6px;
animation-name:fadeIn;
animation-iteration-count:1;
animation-timing-function:linear;
animation-duration:0.3s;
animation-fill-mode:forwards;
		}
		.bottom_popup_img
		{
width:24px; /*16*/
margin-right:8px;
		}
		.bottom_popup_copy
		{
font-family: "Atom_i_demibold";
font-size: 12px;
color: var(--accent-color);
		}
	}
	.main_slide
	{
position:absolute;
left:0px;
top:0px;
width:100%;
height:100%;
background-position:top center;
background-repeat:no-repeat;
background-size:cover; /*30.108*/
z-index:-1;
/*
animation-name:fadeIn;
animation-iteration-count:1;
animation-timing-function:linear;
animation-duration:0.5s;
animation-fill-mode:forwards;
*/
opacity:0;
transition:0.5s opacity linear !important;
	}
.animate_next_slide .main_slide
{
opacity:1;
/*
animation-name:fadeIn;
animation-iteration-count:1;
animation-timing-function:linear;
animation-duration:0.5s;
animation-fill-mode:forwards;
*/
}
	.main_slide_1
	{
background-position:top center; /* center 75%52.1505*/
background-size:cover; /*contain*/
		.main_car
		{
position:absolute;
left:0px;
top:42%;
/*animation-name:carAnim;
animation-iteration-count:1;
animation-timing-function:linear;
animation-duration:3s;
animation-fill-mode:forwards;*/
		}
	}
	.main_qr
	{
position:absolute;
right:56px;
bottom:62px; /*64*/
width:120px;
	}
	.slider_points
	{
display:none;
	}
	.main_button
	{
display:none;
	}
}
@media all and (max-width:768px)
{
.main
{
height:auto;
min-height: unset;
}
.main .main_slide
{
position:static;
height:max(calc(50vh + 55px),400px); /*375*/
background-size:cover;
background-position:top center;
/*
background-size:150%;
background-position:-200px top;
*/
}
.main_car
{
display:none;
}
/*
.main .main_slide_1
{
background-size:100%;
background-position:-25% 0px; 
}*/
.main .main_top_title
{
position:static;
/*top:69.6%;
left:0px;*/
margin:0 auto;
margin-top:0px;/*0 82*/
padding-left:24px;
padding-right:24px;
text-align:center;
font-size: 24px;
}
.main_qr
{
display:none;
}
.main .bottom_popup
{
bottom:unset;
top:calc( max(calc(50vh + 55px),400px) - 158px - 40px); /*39.45*/
left:24px;
/*justify-content:center;*/
}
.main .bottom_popup .bottom_popup_title
{
font-size:16px;
}
.main .main_button
{
max-width:312px;
margin-top:27px;
display:flex;
}

}
@media all and (max-height:667px) and (max-width:768px)
{
.main .main_top_title
{
margin-top:40px;
}
}
@media all and (max-width:768px) and (orientation:landscape)
{

.main .bottom_popup
{
top:10%;
}

}
/*end of main header*/
/*store*/
.store
{
position:relative;
display:flex;
justify-content:space-between;
height:832px;
line-height:1.2;
	&:after
	{
content:'';
position:absolute;
bottom:0px;
left:0px;
z-index:1;
height:2px;
width:100%;
 background: linear-gradient(to right, #ABABAB00, #ABABAB 50.5%, #ABABAB00);
	}
	&:before
	{
content:'';
position:absolute;
top:0px;
left:0px;
z-index:1;
height:2px;
width:100%;
 background: linear-gradient(to right, #ABABAB00, #ABABAB 50.5%, #ABABAB00);
	}
}
.store_left
{
display:flex;
flex-direction:column;
flex-shrink: 0;
background-size:585px;
background-repeat:no-repeat;
background-position:center 80px;
/*max-width:640px;
width:100%;*/
width:640px;
padding-left:56px;
padding-right:56px;
}
.store_left_title
{
font-family: 'Atom-Bold';
font-size: 32px;
color: var(--primary-color);
margin-top:82px; /*80*/
}
.store_left_wrapper
{
display:flex;
gap:34px;
margin-top:67px;
	.store_protone
	{
transform:scale(1);
transition:0.5s all linear;
width:234px;
height:362px;
&:HOVER { transform:scale(1.2); }
	}
	.store_atom
	{
transform:scale(1);
transition:0.5s all linear;
margin-top:63px; /*60*/
width:234px;
height:362px;
&:HOVER { transform:scale(1.2); }
	}
}
.store_left_subtitle
{
font-family: 'Atom-Medium';
font-size:20px;
line-height:1.3;
color: var(--accent-color);
margin-top:58px;
max-width:468px;
}
.store_left_subtitle SPAN
{
font-family: 'Atom_i';
font-size:20px;
color: var(--primary-color);
margin-top:58px;
}
.store_right
{
position:relative;
background-size:cover; /*contain*/
background-repeat:no-repeat;
background-position:bottom center;
width:100%;
/*max-width:640px;*/
padding-left:24px;
padding-right:24px;
}
.store_right_popup
{
position:absolute;
left:56px;
bottom:44px;
width:391px;
height:312px;
border:1px solid #6F6F6F;
border-radius: 24px;
padding:24px;
background:var(--store-popup);
}
.store_right_title_wrapper
{
display:flex;
align-items:center;
gap:8px;
/*margin-top:24px;*/
}
.store_right_title
{
font-family: 'Atom-Medium';
font-size: 32px;
line-height:25px; /*30*/
color: var(--accent-color);
}
.store_right_text
{
font-family:  'Atom_i';
font-size: 20px;
line-height:1.3;
margin-top:18px;
}
.store_button
{
margin-top:24px;
}
/*responsive store*/
@media all and (min-width:1440px)
{

.activity
{
grid-template-rows: 1fr 1fr;
}
.activity_button_wrapper
{
align-self:center;
}

}
@media all and (max-width:1279px)
{
.store
{
flex-direction:column;
align-items:center;
height:auto;
}
.store_left
{
height:832px;
}
.store_right
{
height:832px;
}
}
@media all and (max-width:662px) /*768*/
{
.store
{
padding-top:80px;
width:100%;
&:after{content:none;}
&:before{content:none;}
}
.store .store_left
{
width:100%;
max-width:640px;
height:auto;
padding-left:24px;
padding-right:24px;
background:none !important;
align-items:center;
}
.store .store_right_popup
{
background:var(--store-popup-small);
}
.store .store_left_title
{
margin-top:0px;
text-align:center;
}
.store .store_left_wrapper
{
gap:16px;
margin-top:24px;
}
.store .store_left_wrapper .store_protone
{
width:148px;
height:239px;
}
.store .store_left_wrapper .store_atom
{
width:148px;
height:239px;
margin-top:0px;
}
.store .store_left_subtitle
{
font-family: 'Atom_i_demibold';
font-size: 16px;
margin:0 auto;
width:312px;
margin-top:24px;
}
.store .store_left_subtitle SPAN
{
font-size:16px;
}
.store .store_right
{
margin-top:21px; /*12*/
height:591px;
background-size:auto 436px;
background-position:top center;
}
.store .store_right_popup
{
bottom:0px;
left:0px;
width:100%;
height:252px;
}
.store .store_right_title_wrapper
{
gap:8px; /*3*/
}
.store .store_right_coin
{
width:24px;
}
.store .store_right_title
{
font-family:'Atom-Medium';
font-size:24px;
line-height:24px;
}
.store .store_right_text
{
font-size:16px;
line-height:1.2;
margin-top:18px; /*16*/
}
.store .store_button
{
margin-top:23px;
}

}
@media all and (max-width:579px)
{
.store .store_right
{
background-size:auto 371px;
}
}
/*end of responsive store*/
/*end of store*/
/*activity*/
.activity
{
position:relative;
display:grid;
grid-template-areas: "activity_left activity_right"  
                       		"activity_left activity_button_wrapper";
grid-template-columns: 1fr 1fr;
grid-template-rows:min-content 1fr;
column-gap:32px; /*24*/
padding-left:56px;
padding-right:56px;
margin-top:92px;
margin-bottom:80px;
line-height:1.2;
}
.activity_left
{
 grid-area: activity_left;
}
.activity_right
{
/*max-width:568px;*/
 grid-area: activity_right;
}
.activity_title
{
font-family: 'Atom-Medium';
font-size:32px;
}
.activity_text
{
font-family: 'Atom_i';
font-size: 20px;	
margin-top:7px; /*16*/
}
.activity_button_wrapper
{
 grid-area: activity_button_wrapper;
position:relative;
display:flex;
flex-wrap:wrap;
align-items:flex-start;
gap:24px 16px; /*24 13*/
width:100%;
/*max-width:568px;*/
height:min-content;
padding-top:25px; /*24*/
padding-bottom:24px;
margin-top:11px; /*24*/
	&:after
	{
content:'';
position:absolute;
bottom:0px;
left:0px;
z-index:1;
height:2px;
width:100%;
 background: linear-gradient(to right, #ABABAB00, #ABABAB 50.5%, #ABABAB00);
	}
	&:before
	{
content:'';
position:absolute;
top:0px;
left:0px;
z-index:1;
height:2px;
width:100%;
 background: linear-gradient(to right, #ABABAB00, #ABABAB 50.5%, #ABABAB00);
	}
}
.activity_button
{
position:relative;
display:flex;
align-items:center;
justify-content:center;
width:276px;/*292*/
min-height:72px;
padding-top:26px;
padding-bottom:26px;
padding-left:26px;
padding-right:26px;
/*margin-top:24px;*/
cursor:pointer;
opacity:1;
/*transition:0.3s background linear !important;*/
transition:0.3s opacity linear !important;
	LI
	{
padding-top:4px;
font-size:10px;
list-style-type: square;
	}
	SVG, .activity_button_svg
	{
position:absolute;
left:0px;
top:0px;
z-index:-1;
width:100%;
height:100%;
transition:0.3s all linear;
	}
&:HOVER {opacity:0.7}
}
.white_theme .activity_button_act
{
--Surface-surface-primary:#1A1A1A;
color:#FFF;
cursor:default;
opacity:1 !important;
}
.black_theme .activity_button_act
{
--Surface-surface-primary:#FFF;
color:#1A1A1A;
cursor:default;
opacity:1 !important;
}
/*slider*/
.acitivity_slider_wrapper
{
display:flex;
align-items:center;
flex-direction:column;

.slider_container
{
position:relative;
width:100%;
height:100vh;
max-width:568px;
max-height:568px;
/*margin:0 auto;*/
cursor:pointer;
	.gallery
	{
border-radius:16px;
	}
}
	.images
	{
display:flex;
/*justify-content:center;*/
max-width:calc(100vw - 24px - 24px);/*100%*/
width:568px;
overflow:hidden;
margin-top:22px; /*24*/
gap:16px;
	.image_act
	{
border: 2px solid var(--accent-color) !important;
	}
		.image
		{
width:68px;
height:68px;
border: 2px solid transparent;
border-radius: 8px;
cursor:pointer;
flex-shrink:0;
background-position:center;
background-size:cover;
background-repeat:no-repeat;
			IMG {border-radius: 8px;}
		}
.image_video
{
position:relative;
	&:after
	{
content:'';
position:absolute;
left:0px;
top:0px;
width:65px;
height:66px;
z-index:0;
border: 2px solid transparent;
border-radius: 8px;
background:rgba(0,0,0,0.4) url(./i/act_play.svg);
background-repeat:no-repeat;
background-position:center;
background-size:contain 24px;
	}
}
	}

}
/*end of slider*/
/*responsive*/
@media all and (max-width:1279px)
{

.activity
{
grid-template-areas: "activity_right"
				    "activity_left"
                       	"activity_button_wrapper";
row-gap:32px; /*24*/
grid-template-columns: 1fr;
grid-template-rows:1fr;
}
.activity_button_wrapper
{
padding-top:0px;
padding-bottom:0px;
/*justify-content:center;*/
&:AFTER {content:none;}
&:BEFORE{content:none;}
}

}
@media all and (max-width:662px)
{

.activity
{
padding-left:24px;
padding-right:24px;
margin-top:80px;
margin-bottom:0px;
row-gap:30px; /*32*/
}
.activity_title
{
text-align:center;
}
.activity_text
{
margin-top:24px;
font-size:16px;
}
.acitivity_slider_wrapper .slider_container
{
aspect-ratio:1;
height:auto;
}
.acitivity_slider_wrapper .images
{
margin-top:14px; /*16*/
gap: 12px;
overflow-x:auto;

}
.activity_button_wrapper
{
padding-top:0px;
padding-bottom:0px;
margin-top:0px;
gap: 16px 8px;
&:AFTER {content:none;}
&:BEFORE{content:none;}
}
.activity_button
{
max-width:156px; /*50%*/
padding-left:22px;
padding-right:22px;
padding-top:8px;
padding-bottom:8px;
}

}
/*end of responsive*/
/*end of activity*/
/*about*/
.about
{
position:relative;
line-height:1.2;
width:100%;
min-height:600px;
/*height:100vh;*/
background-position:center;
background-repeat:no-repeat;
background-size:cover;/*contain*/
	&:after
	{
content:'';
position:absolute;
bottom:0px;
left:0px;
z-index:1;
height:2px;
width:100%;
 background: linear-gradient(to right, #ABABAB00, #ABABAB 50.5%, #ABABAB00);
	}
	&:before
	{
content:'';
position:absolute;
top:0px;
left:0px;
z-index:1;
height:2px;
width:100%;
 background: linear-gradient(to right, #ABABAB00, #ABABAB 50.5%, #ABABAB00);
	}
}
.about_title
{
font-family: 'Atom-Medium';
font-size: 32px;
padding-top:80px;
padding-left:56px;
padding-right:56px;
}
.about_img
{
width:268px;
}
.about_one
{
display:flex;
flex-direction:column;
justify-content:flex-end;
align-items:center;
position:relative;
gap:16px; /*16*/
/*width:100%;
max-width:568px;*/
border-radius: 24px;
width:268px; /*278*/
max-height:570px; /*570*/
background-position:top right;
background-repeat:no-repeat;
background-size:auto 164px;
flex-shrink:0;
/*pointer-events:none;*/
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.about_bubble
{
/*display: flex;
flex-direction: column;
align-items: center;
gap: 16px;*/
width: 268px;/*270 268*/
/*height:100%;*/
max-height:286px; /*276*/
padding: 24px 16px;
border-radius: 24px;
border: 1px solid #ABABAB;
background: var(--store-popup);
	.about_one_title
	{
text-align: center;
font-family: 'Atom-Medium';
font-size: 20px;
	}
	.about_one_text
	{
display:flex;
font-family: 'Atom_i';
font-size: 16px;
text-align:center;
margin-top:0px;/*16*/
max-height:0px;
overflow:hidden;
transition: 0.3s all ease-in-out;
/*transition: 0.3s all steps(4, jump-start);*/
	}
}
.about_bubble:HOVER
{
background: var(--store-popup-small);
	.about_one_text
{
position:static;
max-height:300px;
margin-top:16px;
}
}
.about_slider
{
/*margin-top:80px;*/
padding-bottom:160px; /*79*/
min-height:720px;
}
.about_slider .slider_top_wrapper
{
position:absolute;
display:flex;
justify-content:center;
scroll-snap-type:x mandatory;
/*scroll-margin-left: 56px;
scroll-padding-inline-start: 56px;*/
scroll-padding-inline-start:32px;
/*overflow:hidden;*/
overflow-x:auto;
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
scrollbar-height: none;
scroll-behavior: smooth;
width:100%;
height:560px;
}
.about_slider .slider_top_wrapper::-webkit-scrollbar 
{
display: none;
}
.about_slider .slider_wrapper
{
display:flex;
gap:32px; /*32*/
padding-left:56px;
padding-right:56px;
}
.about_slider .slider_wrapper_one
{
display:flex;
flex-shrink:0;
scroll-snap-align:start;
}
.about_slider .slider_points
{
display:flex;
justify-content:center;
align-items:center;
margin-top:48px; /*48*/
gap:8px;
.slider_point
{
width:8px;
height:8px;
border-radius: 50%;
border:1px solid #8A8A8A;
background-color:#8A8A8A;
transition:0.3s all linear;
cursor:pointer;
box-sizing:none;
}
.slider_point:last-child
{
margin-right:0px;
}
.slider_point_active
{
border:1px solid var(--accent-color);
background-color:var(--accent-color);
}
}
/*responsive about*/
@media all and (max-width:1279px)
{

.about_slider
{
min-height:unset;
}
.about_slider .slider_top_wrapper
{
position:static;
justify-content:unset;
}
.about_one
{
pointer-events:none;
}
.about_slider .slider_wrapper_one:first-child
{
border-left:24px solid transparent;
}
.about_bubble
{
background: var(--store-popup-small);
	.about_one_text
{
position:static;
max-height:300px;
margin-top:16px;
}
}

}
@media all and (max-width:662px) /*567*/
{
.black_theme, .white_theme
{
--about-bg:transparent;
}
.about
{
padding-left:0px;/*24*/
padding-right:0px;/*24*/
padding-top:82px; /*80*/
margin-top:56px; /*56*/
background-image: url(./i/about_bg.svg) !important;
/*background-size:1231px;*/
/*background-size:400%;
background-position:top left;*/
	&:after
	{
width:calc(100% - 24px - 24px);
left:24px;
	}
	&:before
	{
width:calc(100% - 24px - 24px);
left:24px;
	}
}
.about_title
{
display:none;
}
.about_bubble .about_one_title
{
text-align:left;
}
.about_bubble .about_one_text
{
text-align:left;
}
.about_slider
{
padding-bottom:81px; /*100*/
}
.about_slider .slider_points
{
margin-top:12px;
}
.about_slider .slider_wrapper
{
padding-left:24px;/*0*/
padding-right:24px;/*0*/
}
.about_slider .slider_top_wrapper
{
/*overflow-x:auto;*/
pointer-events:auto;
scroll-padding-left: 0px;
scroll-padding-inline-start: 24px;
}
.about_slider .slider_wrapper_one:first-child
{
border-left:0px;
}
}
/*end of responsive*/
/*end of about*/
/*how*/
.how
{
line-height:1.2;
}
.how_title
{
font-family: 'Atom-Medium';
font-size: 32px;
margin-top:80px;
padding-left:56px;
padding-right:56px;
}
.how_slider
{

margin-top:80px;
padding-bottom:79px;/*136*/
}
.how_slider .slider_top_wrapper
{
display:flex;
scroll-snap-type:x mandatory;
/*scroll-margin-left: 56px;
scroll-padding-inline-start: 56px;*/
scroll-padding-inline-start:32px;
/*overflow:hidden;*/
overflow-x:auto;
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
scrollbar-height: none;
scroll-behavior: smooth;
width:100%;
}
.how_slider .slider_top_wrapper::-webkit-scrollbar 
{
display: none;
}
.how_slider .slider_wrapper
{
display:flex;
gap:32px;
padding-left:56px;
padding-right:56px;
}
.how_slider .slider_wrapper_one
{
display:flex;
flex-shrink:0;
scroll-snap-align:start;
}
.how_slider .slider_wrapper_one:first-child
{
border-left:24px solid transparent;

/*width:calc(568px + 24px);*/
}
.how_slider .slider_points
{
display:flex;
justify-content:center;
align-items:center;
margin-top:48px;
gap:8px;
.slider_point
{
width:8px;
height:8px;
border-radius: 50%;
border:1px solid #8A8A8A;
background-color:#8A8A8A;
transition:0.3s all linear;
cursor:pointer;
box-sizing:none;
}
.slider_point:last-child
{
margin-right:0px;
}
.slider_point_active
{
border:1px solid var(--accent-color);
background-color:var(--accent-color);
}
}
.how_one
{
display:flex;
flex-direction:column;
/*justify-content:flex-end;*/
align-items:center;
position:relative;
/*width:100%;
max-width:568px;*/
border-radius: 24px;
width:calc(568px + 0px);
height:357px;
background-position:top right;
background-repeat:no-repeat;
background-size:auto 164px;
flex-shrink:0;
padding-left:16px;
padding-right:16px;
padding-bottom:43px;
pointer-events:none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
	&first-child{

	}
	&:after
	{
content:'';
position:absolute;
bottom:0px; 
left:0px;
z-index:1;
height:2px;
width:100%;
 background: linear-gradient(to right, #ABABAB00, #ABABAB 50.5%, #ABABAB00);
	}
}
.how_one_number
{
display:flex;
justify-content:center;
align-items:center;
width: 112px;
height: 112px;
margin-top:108px;
flex-shrink: 0;
border-radius: 100%;
background:var(--store-popup-small);
font-family: 'Atom-Medium';
font-size: 64px;
pointer-events:none;
}
.how_one_title
{
color: var(--accent-color);
text-align: center;
font-family: 'Atom-Medium';
font-size: 20px;
margin-top:24px;
pointer-events:none;
}
.how_one_text
{
text-align: center;
font-family: 'Atom_i';
font-size: 16px;
margin-top:10px; /*8*/
pointer-events:none;
}
/*responsive of how*/
@media all and (max-width:662px) /*567*/
{
.how
{
position:relative;
padding-left:24px;
padding-right:24px;
	&:after
	{
content:'';
position:absolute;
bottom:0px; 
left:24px;
z-index:1;
height:2px;
width:calc(100% - 24px - 24px);
 background: linear-gradient(to right, #ABABAB00, #ABABAB 50.5%, #ABABAB00);
	}
}
.how_title
{
padding-left:0px;
padding-right:0px;
text-align:center;

}
.how_slider
{
margin-top:32px;
padding-bottom:49px; /*82*/
}
.how_slider .slider_wrapper
{
padding-left:0px;
padding-right:0px;
}
.how_slider .slider_top_wrapper
{
/*overflow-x:auto;*/
pointer-events:auto;
scroll-padding-left: 0px;
}
.how_slider .slider_wrapper_one
{
/*width:100%;
max-width:568px;*/
flex-shrink:0;
}
.how_slider .slider_wrapper_one:first-child
{
border-left:0px;
}
.how_slider .slider_points
{
margin-top:24px; /*24*/
}
.how_one
{
height:414px;
width:calc(100vw - 24px - 24px);
max-width:568px;
border:1px solid #5D5D5D;
padding-left:16px;
padding-right:16px;
&:after {content:none;}
}
.how_one_title
{
text-align:left;
}
.how_one_text
{
text-align:left;
}
.how_slider .slider_wrapper
{
gap:24px; /*32 16*/
}
}
/*end of responsive*/
/*end of how*/
/*partners*/
.partners
{
display:flex;
justify-content:center;
gap:32px;
max-width:1168px;
width:100%;
margin:0 auto;
padding-left:56px;
padding-right:56px;
margin-top:80px;
line-height:1.2;
}
.partners_left, .partners_right
{
display:flex;
flex-direction:column;
justify-content:space-around;
}
.partners_top
{
margin-bottom:31px; /*32*/
}
.partners_title
{
font-family: 'Atom-Medium';
font-size: 32px;
}
.partners_text
{
font-family: "Atom_i";
font-size: 20px;
/*line-height:22px;*/
margin-top:25px; /*24*/
}
.partners_block
{
position:relative;
display:flex;
flex-direction:column;
align-items:flex-end;
width: 568px;
height: 568px;
border-radius: 24px;
border: 1px solid #ABABAB;
padding:24px;
/*padding-right:23px;*/
/*padding-top:19px;*/
}
.partners_block_top_img
{
position:absolute;
left:24px;
top:24px;
}
.partners_block_left_img
{
position:absolute;
left:0px;
bottom:0px;
width:568px;
height:568px;
background-size:360px, 100%; /*360 312*/
background-position:bottom left, center;
background-repeat:no-repeat;
}
.partners_block_title
{
color: var(--accent-color);
text-align: right;
font-family:'Atom-Medium';
font-size: 24px;
}
.partners_block_top_text
{
width:342px;/*344*/
margin-top:9px;/*8*/
text-align: right;
font-family: "Atom_i";
font-size: 16px;
/*line-height:17px;*/
}
.partners_block_hide_mobile
{
display:flex;
flex-direction:column;
align-items:flex-end;
}
.partners_block_bottom_title
{
text-align: right;
font-family: 'Atom_i_medium';
font-size: 16px;
margin-bottom:16px;
}
.partners_block_bottom_text
{
width: 244px; /*224 220*/
text-align: right;
font-family: "Atom_i";
font-size: 16px;
margin-top:24px;
	UL
	{
direction:rtl;
margin-left:0;
margin-right:24px; /*24*/
	}
		LI
	{
padding-right:3px;
list-style-type:square;
	}
}
.partners_block_end_text
{
text-align: right;
font-family: "Atom_i";
font-size: 20px;
margin-top:32px;
}
.partners_block_button
{
display:none;
}
/*responsive of partners*/
@media all and (max-width:1248px)
{

.partners
{
justify-content:unset;
align-items:flex-start;
margin-left:0px;
margin-top:0px;
flex-direction:column;
}
.partners_block
{
align-self:center;
}
.partners_block_end_text
{
text-align:left;
}

}
@media all and (max-width:662px)
{

.partners
{
padding-left:24px;
padding-right:24px;
margin-top:80px;
}
.partners_top
{
margin-bottom:23px;
}
.partners_title
{
text-align:center;
}
.partners_block
{
position:relative;
width:100%;
height:auto;
background:var(--partners-bg);
background-size:100% 312px;
background-repeat:no-repeat;
padding-top:21px;
	&:after
	{
content:'';
position:absolute;
top:312px;
left:0px;
z-index:1;
height:1px;
width:100%;
 background: linear-gradient(to right, #ABABAB00, #ABABAB 50.5%, #ABABAB00);
	}
}
.partners_block_title
{
width:200px;
height:288px;/*312*/
color:#FFF;
}
.partners_block_top_img
{
width:56px;
top:22px;
}
.partners_block_left_img
{
width:190px; /*171px 50%*/
height:312px;
top:108px;
bottom:unset;
background-position:top left;
background-size: 100%, 100%; /*156px*/
}
.partners_block_top_text
{
width:100%;
margin-top:28px; /*29*/
text-align:unset;
}
.partners_text
{
font-size:16px;
}
.partners_block_bottom_text
{
width:100%;
/*max-height:0px;
overflow:hidden;
transition:0.3s all linear !important;
*/
margin-top:24px;
text-align:left;
}
.partners_block_hide_mobile
{
width:auto;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
max-height:106px; /*78*/
overflow:hidden;
text-overflow:ellipsis;
transition:0.3s all linear !important;
}
.partners_block_bottom_text_show
{
  text-overflow: ellipsis;
  -webkit-line-clamp: unset;
max-height:500px;
overflow: hidden;
/*transition:0.3s all linear !important;*/
/*margin-top:24px;*/
}
.partners_block_bottom_title
{
text-align:left;
}
.partners_block_bottom_text UL
{
direction:ltr;
padding-left: 20px;
margin-right:0px;
	LI
	{
padding:unset;
	}
}
.partners_block_bottom_text LI::marker
{

}


.partners_block_button
{
width:100%;
display: flex;
padding: 10.5px 32px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 20px;
border: 1px solid #ABABAB;
background: var(--button-partners);
margin-top:23px;/*24*/
font-family: 'Atom_i_demibold';
font-size: 16px;
}
.partners_block_end_text
{
font-size:16px;
margin-top:24px;
}

}
/*end of responsive*/
/*end of partners*/
/*projects*/
.projects
{
line-height:1.2;
display:flex;
flex-direction:column;
align-items:center;
margin-top:80px;
}
.projects_title
{
text-align: center;
font-family: 'Atom-Bold';
font-size: 32px;
margin-bottom:20px; /*23*/
}
.projects_hr
{
height:1px; /*2*/
width:min(44.375%,568px);
min-width:312px;
background: linear-gradient(to right, #ABABAB00, #ABABAB 50.5%, #ABABAB00);
}
.projects_hr_desktop
{
position:relative;
top:-56px; /*-56*/
height:1px; /*2*/
width:min(44.375%,568px);
min-width:312px;
margin:0 auto;
background: linear-gradient(to right, #ABABAB00, #ABABAB 50.5%, #ABABAB00);
}
.projects_slider
{
margin-top:39px; /*40*/
max-width:100%;
}
.projects_slider .cursor_move .slider_wrapper_one:first-child
{
border-left:24px solid transparent;
}
.projects_slider .slider_top_wrapper
{
display:flex;
/*justify-content:center;*/
scroll-snap-type:x mandatory;
scroll-padding-inline-start:32px;
overflow-x:scroll;
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
scrollbar-height: none;
scroll-behavior: smooth;
width:100%;
height:268px;
}
.projects_slider .slider_top_wrapper::-webkit-scrollbar 
{
display: none;
}
.projects_slider .slider_wrapper
{
display:flex;
gap:32px; /*32*/
padding-left:56px;
padding-right:56px;
}
.projects_slider .slider_wrapper_one
{
display:flex;
flex-shrink:0;
scroll-snap-align:start;
}
.projects_slider .slider_points
{
display:flex;
justify-content:center;
align-items:center;
margin-top:86px; /*88 48*/
gap:8px;
.slider_point
{
width:8px;
height:8px;
border-radius: 50%;
border:1px solid #8A8A8A;
background-color:#8A8A8A;
transition:0.3s all linear;
cursor:pointer;
box-sizing:none;
}
.slider_point:last-child
{
margin-right:0px;
}
.slider_point_active
{
border:1px solid var(--accent-color);
background-color:var(--accent-color);
}
}
.projects_one
{
display:flex;
flex-direction:column;
justify-content:flex-end;
align-items:center;
position:relative;
gap:24px; /*16*/
border-radius: 24px;
border: 1px solid #ABABAB;
color:#ffffff;
width:568px; 
height:268px;
flex-shrink:0;
pointer-events:none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  background:var(--projects_one_bg);
}
	.projects_one_title
	{
position:absolute;
right:24px;
top:22px; /*24*/
font-family: 'Atom-Medium';
font-size: 24px;
	}
	.projects_one_text
	{
position:absolute;
right:24px;
top:53px; /*61*/
width: 244px;
text-align: right;
font-family: 'Atom_i';
font-size: 16px;
margin-top:8px;
	}
	.projects_one_logo
	{
position:absolute;
top:24px;
left:24px;
max-width:56px;
	}
	.projects_one_img
	{
position:absolute;
top:0px;
left:0px;
max-width:268px;
	}
/*responsive of projects*/
@media all and (max-width:662px) /*567*/
{

.projects_slider
{
margin-top:31px; /*32*/
}
.projects_slider .slider_top_wrapper
{
height:499px;
}
.projects_slider .slider_wrapper
{
gap:16px;
padding-left:24px;
padding-right:24px;
}
.projects_one
{
justify-content:unset;
background:var(--projects_one_bg_small);
height:499px;
width:296px;
gap:0px;
}
.projects_one_title
{
top:19px; /*20*/
right:23px;
width:calc(100% - 23px - 23px - 56px - 6px); /*-8px*/
display:flex;
align-items:center;
min-height:58px;
}
.projects_one_logo
{
right:auto;
left:22px; /*23*/
top:20px;
}
.projects_one_img
{
position:static;
margin-top:0px; /*6/
/*margin-top:78px;*/
max-height:296px;
max-width:296px;
width:296px;
}
.projects_one_text
{
position:static;
text-align:unset;
width:calc( 100% - 17px * 2);
margin-top:17px;
}
.projects_slider .cursor_move .slider_wrapper_one:first-child
{
border-left:0px;
}
.projects_hr_desktop
{
display:none;
}
.projects_slider .slider_points
{
margin-top:24px;
}

}
/*end of responsive*/
/*end of projects*/
/*map*/
.map
{
position:relative;
line-height:1.2;
width:100%;
min-height:600px;
/*height:100vh;*/
background-position:center;
background-repeat:no-repeat;
background-size:cover;/*contain*/
background-image:var(--map-bg);
margin-top:120px; /*80*/
padding-top:80px;
padding-bottom:80px;
padding-left:56px;
padding-right:56px;

	&:after
	{
content:'';
position:absolute;
bottom:0px;
left:0px;
z-index:1;
height:2px;
width:100%;
 background: linear-gradient(to right, #ABABAB00, #ABABAB 50.5%, #ABABAB00);
	}
	&:before
	{
content:'';
position:absolute;
top:0px;
left:0px;
z-index:1;
height:2px;
width:100%;
 background: linear-gradient(to right, #ABABAB00, #ABABAB 50.5%, #ABABAB00);
	}
}
.map_title
{
font-family: 'Atom-Medium';
font-size: 32px;
color:#ffffff;
}
.map_wrapper
{
display:flex;
justify-content:center;
height:568px;
margin-top:32px;
}
.map_left
{
height:100%;
width:100%;
max-width:700px;
border-radius: 24px 24px 0px 24px;
background-size:cover;
background-repeat:no-repeat;
background-position:100% center;
}
.map_right
{
display:flex;
justify-content:center;
width:100%;
border-radius: 24px 24px 24px 0px;
border: 1px solid #ABABAB;
flex-shrink:0;
/*width:calc(100% - 568px);*/
width:468px;
background-color:var(--bg-color);
transition:0.3s background-color linear !important;
}
.map_right_wrapper
{
display:flex;
flex-direction:column;
align-items:center;
width:468px;
}
.map_right_img
{
margin-top:54px; /*48*/
border-radius: 24px;
width:404px; /*342*/
}
.map_right_title_mobile
{
display:none;
font-family: 'Atom-Medium';
color:#ffffff;
font-size:32px;
text-align: center;
margin-top:32px;
}
.map_right_text
{
font-family: "Atom_i";
font-size: 20px;
margin-top:25px;/*17*/
max-width:404px;
}
.map_button
{
margin-top:23px;/*24*/
max-width:404px;
}
/*responsive of map*/
@media all and (max-width:991px)
{

.map_wrapper
{
display:flex;
flex-direction:column;
justify-content:unset;
align-items:center;
height:auto;
}
.map_left
{
border-radius:24px;
border-bottom-left-radius:0px;
border-bottom-right-radius:0px;
max-width:unset;
width:100%;
height:568px;
}
.map_right
{
width:100%;
height:568px;
border-radius:24px;
border-top-left-radius:0px;
border-top-right-radius:0px;
}

}
@media all and (max-width:662px)
{

.map
{
background:none;
padding-left:0px;
padding-right:0px;
padding-bottom:0px;
padding-top:0px;
margin-top:80px;
}
.map_wrapper
{
margin-top:0px;
}
.map_title
{
display:none;
}
.map_left
{
position:relative;
border:0;
border-radius:0;
width:100%;
height:292px;
	&:after
	{
content:'';
position:absolute;
left:0px;
top:0px;
width:100%;
height:37px;
background: linear-gradient(180deg, #639cfd 0%, rgba(99, 156, 253, 0) 100%);
background-repeat:no-repeat;
	}
}
.map_right
{
border:0;
border-radius:0;
width:100%;
height:auto;
background-image:var(--map-bg);
padding-left:24px;
padding-right:24px;
padding-bottom:73px; /*76*/
}
.map_right_title_mobile
{
display:flex;
}
.map_right_img
{
display:none;
}
.map_right_text
{
font-size: 16px;
margin-top:16px;
color:#ffffff;
}
.map_button
{
margin-top:15px;/*16*/
width:100%;
}

}
/*end of responsive*/
/*end of map*/