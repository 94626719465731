.big_slider
{
.next_image .sub_div, .prev_image .sub_div, .current_image .sub_div
{
max-width:100%;
height:auto;
opacity:0;
/*transition:0.3s opacity linear;*/
background-position:center;
background-repeat:no-repeat;
background-size:contain;
}
.one_image
{
transition:0.3s all linear;
max-width:100%;
max-height:100%;
height:auto;
align-self:center;
 flex-grow:0;
flex-shrink:0;
pointer-events:none;
}
.next_image .image_animate_show, .prev_image .image_animate_show, .current_image .image_animate_show
{
opacity:1;
}
.next_image, .prev_image, .current_image
{
display:flex;
align-items:center;
justify-content:center;
position:absolute;
top:0px;
background-position:center;
background-repeat:no-repeat;
background-size:contain;
width:100%;
height:100%;
}
.prev_image
{
/*left:-100vw;*/
opacity:0;
animation-name: leftAnimationFade;
animation-timing-function: linear;
animation-duration: 0.5s;
animation-fill-mode: forwards;
z-index:0;
}

.next_image
{
/*left:100vw;*/
opacity:0;
animation-name: rightAnimationFade;
animation-timing-function: linear;
animation-duration: 0.5s;
animation-fill-mode: forwards;
transition:0.3s opacity linear;
z-index:1;
}
@media all and (max-width:991px)/*768px*/
{

.prev_image
{
opacity:0;
animation-name: leftAnimationFade;
animation-timing-function: linear;
animation-duration: 0.5s;/*0.3*/
animation-fill-mode: forwards;
pointer-events:none;
}
.next_image
{
opacity:0;
opacity:unset;
transition:unset;
animation-name: rightAnimationFade;
animation-timing-function: linear;
animation-duration: 0.5s;/*0.3*/
animation-fill-mode: forwards;
/*pointer-events:none;*/
/*	IFRAME
	{
touch-action: none;
	}*/
}
.gallery_swiper
{
position:absolute;
left:0px;
top:0px;
width:100%;
height:100%;
z-index:2;
}

}
.gallery
{
position:absolute;
top:0px;
left:0px;
width:100%;
height:100%;
overflow:hidden;
/*background-color:#222222;*/
}
.hide_image
{
display:none;
}
@keyframes leftAnimationFade {
  0% {
opacity:1;
  }
  100% {
opacity:0;
  }
}

@keyframes rightAnimationFade {
  0% {
opacity:0;
  }
  100% {
opacity:1;
  }
}

.cursorLeft
{
cursor: url(./i/arrow_left.svg), pointer;
}
.cursorRight
{
cursor: url(./i/arrow_left.svg), pointer;
}


.disableAnimation
{
opacity:1;
-webkit-transition:none !important;
-moz-transition:none !important;
-o-transition:none !important;
transition:none !important;
animation-name:none !important;
}

	.images
	{
		.image
		{
position:relative;
border-radius:8px;
width:64px;/*60*/
height:64px;
border:2px solid transparent; /*transparent*/
cursor:pointer;
flex-shrink:0;
background-position:center;
background-size:cover;
background-repeat:no-repeat;
		}
.image_video
{
position:relative;
	&:after
	{
content:'';
position:absolute;
left:0px;
top:0px;
width:65px;
height:66px;
z-index:0;
border: 2px solid transparent;
border-radius: 8px;
background:rgba(0,0,0,0.4) url(./i/act_play.svg);
background-repeat:no-repeat;
background-position:center;
background-size:contain 24px;
	}
}
	.image_act
	{
border:2px solid blue;
	}
display:flex;
max-width:calc(100vw - 24px - 24px);/*100%*/
overflow:hidden;
margin-top:16px; /*24*/
gap:6px;/*9 8*/
	}

}