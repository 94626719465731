/*not found*/
.notfound
{
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;
width:100%;
height:calc(100vh - 290px);
padding-left:56px;
padding-right:56px;
	.title
	{
margin-top:88px;
font-family: 'Atom-Medium';
font-size:80px;
text-align:center;
	}
	 .text
	 {
display:flex;
flex-direction:column;
align-items:center;
font-family: 'Atom-Medium';
font-size:18px;
text-align:center;
	 }
	 A
	 {
color:var(--accent-color);
/*text-decoration:underline;*/
transition:0.3s all linear;
&:HOVER {color:var(--accent-color);}
	 }
}