/*footer*/
.footer_mobile
{
display:none;
}
.footer
{
display:flex;
width:100%;
max-width:var(--maxwidth-portal);
line-height:1.2;
padding-top:32px;
padding-bottom:25px;
padding-left:56px;
padding-right:56px;
	.footer_logo
	{
width:127px;
	}
	.footer_menu
	{
display:flex;
flex-direction:column;
position:relative;
margin-top:22px; /*32*/
	&:before
	{
content:'';
position:absolute;
top:-7px; /*-17 -16*/
left:0px;
z-index:1;
height:1px;
width:145px;
 background: linear-gradient(to right, #ABABAB00, #ABABAB 50.5%, #ABABAB00);
	}
	}
	.footer_column_1
	{
position:relative;
width:193px;
display:flex;
flex-direction:column;
justify-content:flex-start; /*flex end DEBUG*/
	}
	.footer_menu_item
	{
position:relative;
font-family: 'Atom-Medium';
font-size: 16px;
color: var(--primary-color);
margin-top:12px; /*8*/
transition:0.3s color linear;
cursor:pointer;
	}
	.footer_menu_item:HOVER, .footer_menu_item_act
	{
color:var(--accent-color) !important;
	}
	.footer_column_2
	{
position:relative;
display:flex;
flex-direction:column;
align-items:center;
justify-content:flex-end;
width:calc(100% - 248px - 193px);
	}
	.footer_vert_hr_1
	{
position:absolute;
right:0px;/*48*/
top:0px;
height:100%;
width:1px;
 background: linear-gradient(to bottom, #ABABAB00, #ABABAB 50.5%, #ABABAB00);
	}
	.footer_social
	{
position:relative;
display:flex;
justify-content:center;
gap:24px;
padding-bottom:15px;/*24*/
width:200px;
	&:after
	{
content:'';
position:absolute;
bottom:0px;
left:0px;
z-index:1;
height:1px;
width:100%;
 background: linear-gradient(to right, #ABABAB00, #ABABAB 50.5%, #ABABAB00);
	}
		.footer_soc_img
		{
opacity:1;
transition:0.3s all linear;
width:24px;
&:HOVER{opacity:0.5}
		}
	}
	.footer_copy
	{
font-family: 'Atom_i';
font-size: 12px;
text-align: center;
color: var(--primary-color);
margin-top:15px; /*22*/
	}
	.footer_copy_link
	{
display:block;
font-family: 'Atom_i';
font-size: 12px;
text-align: center;
color: var(--accent-color);
margin-top:3px; /*4*/
	}
	.footer_column_3
	{
position:relative;
width:248px;
padding-left:47px;/*48*/
display:flex;
flex-direction:column;
justify-content:flex-end;
	}
	.footer_vert_hr_2
	{
position:absolute;
left:0px;
top:0px;
height:100%;
width:1px;
 background: linear-gradient(to bottom, #ABABAB00, #ABABAB 50.5%, #ABABAB00);
	}
	.footer_qr
	{
width:112px;
	}
	.footer_title
	{
font-family: 'Atom-Medium';
font-size: 16px;
color: var(--accent-color);
margin-top:16px;
	}
	.footer_text
	{
font-family: 'Atom_i';
font-size: 12px;
margin-top:7px; /*8*/
	}
}
/*responsive*/
@media all and (max-width:768px)
{

.footer
{
flex-direction:column;
align-items:center;
}
.footer .footer_desktop
{
display:none;
}
.footer .footer_mobile
{
display:flex;
flex-direction:column;
}
.footer .footer_column_1, .footer .footer_column_2, .footer .footer_column_3
{
width:312px;
}
.footer .footer_column_2
{
order:3;
margin-top:48px;
}
.footer .footer_column_3
{
order:2;
margin-top:31px; /*48*/
padding-left:0px;
}
.footer .footer_menu:before
{
width:100%;
}
.footer .footer_vert_hr_1, .footer .footer_vert_hr_2
{
display:none;
}
.footer .footer_menu
{
flex-direction:row;
gap:20px; /*16*/
padding-bottom:19px; /*16*/
	&:after
	{
content:'';
position:absolute;
bottom:0px; /*-17 -16*/
left:0px;
z-index:1;
height:1px;
width:100%;
 background: linear-gradient(to right, #ABABAB00, #ABABAB 50.5%, #ABABAB00);
	}
}
.footer .footer_title
{
text-align:center;
}
.footer .footer_app_apple
{
margin-top:23px; /*24*/
}
.footer .footer_app_google
{
margin-top:16px;
}
.footer .footer_text
{
text-align:center;
margin-top:24px;
}
.footer .footer_mobile_hr
{
margin-top:47px;/*48*/
height:1px;
width:100%;
 background: linear-gradient(to right, #ABABAB00, #ABABAB 50.5%, #ABABAB00);
}


}
/*end of responsive*/
/*end of footer*/