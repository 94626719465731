/*@import "./variables.scss";*/
$maxwidth-portal:2560px;
@keyframes fadeInMenu {
    0% {
        opacity: 0;
        width:0%;
        left:50%;
    }
    100% {
        opacity: 1;
        width:100%;
        left:0%;
    }
}

.menu_button
{
display:none;
}
.menu
{
position:fixed;
z-index:200;
 left:50%;
top:0px;
transform: translateX(-50%);
display:flex;
justify-content:space-between;
padding-left:56px;
padding-right:56px;
padding-top:40px;/*40*/
/*padding-bottom:px;*/
height:88px;
width:100%;
max-width:var(--maxwidth-portal);
margin:0 auto;
transition:0.3s all linear !important;
/*
transition-property: background-color, color, background, opacity !important;
transition-duration: 300ms, 300ms  !important;
*/
background-color:var(--bg-color);
	&:after
	{
content:'';
position:absolute;
bottom:0px;
left:0px;
z-index:202;
height:1px;
width:100%;
 background: linear-gradient(to right, #ABABAB00, #ABABAB 50.5%, #ABABAB00);
	}
}
.menu_sticky
{
background-color:var(--bg-color);
/*background-color:rgba(3, 6, 42, 0.7);*/
/*background-color:rgba(0, 0, 0, 0.1);*/
/*background-color:rgba(0, 0, 0, 0.5);*/
/*backdrop-filter: blur(2px);*/
padding-top:16px;
height:51px; /*64*/
	.logo
	{
position:relative;
top:-3px;
	}
	.menu_wrapper
	{
top:0px;
	}
}
.logo
{
cursor:pointer;
height:24px;
/*padding-top:5px;*/
}
.menu_theme
{
cursor:pointer;
height:24px;
margin-left:23px; /*40*/
}
.menu_wrapper
{
display:flex;
position:relative;
top:0px; /*9*/
}
.menu_item
{
position:relative;
font-family: 'Atom-Medium';
font-size: 16px;
color: var(--primary-color);
height:25px; /*23*/
margin-right:16px; /*17 16*/
transition:0.3s color linear;
cursor:pointer;
	&:after
	{
position:absolute;
bottom:0px;
left:50%;
height:1px;
width:0%;
transition:0.3s all linear;
content:'';
/*background: linear-gradient(to right, #ABABAB 0%, #ABABAB00 100%), #ABABAB*/
background: linear-gradient(to right, #ffffff00, var(--accent-color) 50.5%, #ffffff00);
opacity: 0;
	}
}
.menu_item:last-child
{
margin-right:0px;
}
.menu_item:HOVER, .menu_item_act, .menu_item_act_forever
{

color:var(--accent-color) !important;
	&:after
	{
left:0%;
width:100%;
opacity:1;
/*
animation-name:fadeInMenu;
animation-iteration-count:1;
animation-timing-function:linear;
animation-duration:0.3s;
animation-fill-mode:forwards;*/
	}
/*
background-repeat:none;
background-position:bottom center;
background-size:100%;
background-image:url('./i/black/menu_bottom.svg');*/
}
.menu_sticky
{
	.menu_item:HOVER, .menu_item_act
	{
color:var(--accent-color) !important;
	}
}
@media all and (max-width: $maxwidth-portal)
{
.menu
{
left:0px;
transform:none;
}
}
@media all and (max-width:991px)
{
.menu_theme
{
position:absolute; 
right:24px;
top:70px;
}
.menu_mobile_wrapper
{
display:flex;
flex-direction:column;
align-items:center;
width:100%;
/*padding-left:24px;
padding-right:24px;*/
}
.menu_hr
{
width:100%;
height:1px;
 background: linear-gradient(to right, #ABABAB00, #ABABAB 50.5%, #ABABAB00);
 margin-top:28px; /*24*/
 }
 .menu_ftitle
 {
font-family: 'Atom-Medium';
font-size: 16px;
line-height:1.2;
color: var(--accent-color);
text-align: center;
margin-top:47px; /*48*/
 }
 .menu_app_apple
 {
 margin-top:24px; /*20 24*/
 }
 .menu_app_google
 {
 margin-top:16px; /*16*/
 }
 .menu_btitle
 {
font-family: 'Atom_i';
font-size: 12px;
line-height:1.2;
text-align: center;
color: var(--primary-color);
margin-top:24px; /*22 24*/
 }
.menu
{
height:55px;
padding-top:16px;
padding-left:24px;/*30 28*/
padding-right:24px;/*30 28*/
	&:after
	{
width:calc(100% - 24px - 24px );
	}
	&:before
	{
content:'';
position:fixed;
top:0px;
left:0px;
z-index:202;
width:100%;
height:56px;
background-color:var(--bg-color);
	}
}
.menu_sticky
{
padding-top:13px; /*31 28*/
	.logo
	{
top:-1px;
	}
}
.logo
{
position:relative;
width:122px;
z-index:202;
}
.menu_button
{
position:relative;
z-index:202;
display:block;
cursor:pointer;
}
/*
.menu_wrapper_mobile
{
transition:1s opacity linear;
}
*/
.menu_wrapper
{
position:fixed;
top:0px;
left:0px;
z-index:201;
overflow-y:auto;
width:100%;
height:100%;
background-color:var(--bg-color);
backdrop-filter: blur(2px);
flex-direction:column;
align-items:flex-start;
padding-left:24px;
padding-right:24px;
justify-content:flex-start;
padding-top:0px;
opacity:0;
pointer-events:none;
/*transition:1s opacity linear;*/
}
.menu_wrapper_show
{
pointer-events:auto;
opacity:1;
}
.menu_item
{
margin-right:0px;
margin-top:19px; /*16*/
color:var(--primary-color);
font-size: 20px;
	&:first-child
	{
margin-top:74px;/*62*/
	}
	&:after
	{
bottom:-5px;
	}
}
	.menu_item:HOVER, .menu_item_act
	{
color:var(--accent-color) !important;
	}

.menu_social
{
position:relative;
display:flex;
justify-content:center;
gap:24px;
margin-top:48px; /*46 48*/
padding-bottom:24px;
	&:after
	{
content:'';
position:absolute;
bottom:0px;
left:0px;
z-index:202;
height:1px;
width:100%;
 background: linear-gradient(to right, #ABABAB00, #ABABAB 50.5%, #ABABAB00);
	}
}
.menu_copy
{
font-family: 'Atom_i';
font-size: 12px;
text-align: center;
color: var(--primary-color);
margin-top:22px; /*24*/
padding-bottom:24px;
}

}