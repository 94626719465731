/*reset all*/
*,
*::before,
*::after {
  box-sizing: border-box;
	vertical-align: baseline;
	font-weight: inherit;
	font-family: inherit;
	font-style: inherit;
	font-size: 100%;
	border: 0 none;
	outline: 0;
	padding: 0;
	margin: 0;
}


ul[class],
ol[class] {
  padding: 0;
}


body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}
H1,H2,H3,H4,H5,H6
{
font-size:inherit;
line-height:inherit;
font-weight:inherit;
margin:0;
padding:0;
}


body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}


ul[class],
ol[class] {
  list-style: none;
}


a:not([class]) {
  text-decoration-skip-ink: auto;
}


img {
  max-width: 100%;
  display: block;
}


article > * + * {
  margin-top: 1em;
}


input,
button,
textarea,
select {
  font: inherit;
}
A
{
outline:none;
text-decoration:none;
}
INPUT, TEXTAREA
{
outline:none;
}
/*end reset all*/