@import "./scss_base/_reset.scss";
:root
{
--maxwidth-portal: 2560px;
}
/*colors*/
.white_theme
{
--primary-color: #1a1a1a; /*for text and other*/
--accent-color: #4891ff;
--bg-color:#ffffff;
--button-gradient:linear-gradient(180deg, #e2a5ff 0%, #8dadff 45.7%, #81affd 70.6%, #8ac5fb 100%);
--button-gradient-pressed:linear-gradient(180deg, #813DFF -35.37%, #5786FF 26.5%, #639CFD 60.2%, #58ACF9 100%);
--store-popup:#ffffff;
--store-popup-small:linear-gradient(163deg, rgba(93, 107, 184, 0.00) -6.03%, rgba(137, 168, 190, 0.24) 112.54%), #FFF;
--about-bg:linear-gradient(180deg, rgba(129, 61, 255, 0.50) -35.37%, rgba(87, 134, 255, 0.50) 26.5%, rgba(99, 156, 253, 0.50) 60.2%, rgba(88, 172, 249, 0.50) 100%);
--map-bg:linear-gradient(180deg, rgba(129, 61, 255, 0.50) -35.37%, rgba(87, 134, 255, 0.50) 26.5%, rgba(99, 156, 253, 0.50) 60.2%, rgba(88, 172, 249, 0.50) 100%);
--Surface-surface-primary:#FFF;
--Stroke-Clickable:#ABABAB;
--radial-partners-bg:radial-gradient(47.76% 47.76% at 33.54% 64.26%, rgba(130, 187, 254, 0.20) 0%, rgba(114, 179, 255, 0.00) 100%);
--button-partners:linear-gradient(163deg, rgba(93, 107, 184, 0.00) -6.03%, rgba(137, 168, 190, 0.24) 112.54%), #FFF;
--partners-bg:linear-gradient(180deg, rgba(129, 61, 255, 0.50) -35.37%, rgba(87, 134, 255, 0.50) 26.5%, rgba(99, 156, 253, 0.50) 60.2%, rgba(88, 172, 249, 0.50) 100%);
--projects_one_bg:linear-gradient(180deg, #813DFF -35.37%, #5786FF 26.5%, #639CFD 60.2%, #58ACF9 100%);
--projects_one_bg_small:linear-gradient(180deg, rgba(129, 61, 255, 0.50) -35.37%, rgba(87, 134, 255, 0.50) 26.5%, rgba(99, 156, 253, 0.50) 60.2%, rgba(88, 172, 249, 0.50) 100%);
}
.black_theme
{
--primary-color: #ffffff; /*for text and other*/
--accent-color: #48ffb2;
--bg-color: #1a1a1a;
--button-gradient:linear-gradient(180deg, #E2A5FF -35.37%, #8DADFF 26.5%, #81AFFD 60.2%, #8AC5FB 100%);
--button-gradient-pressed:linear-gradient(180deg, #813DFF -35.37%, #5786FF 26.5%, #639CFD 60.2%, #58ACF9 100%);
--store-popup:#1a1a1a;
--store-popup-small:linear-gradient(163deg, rgba(137, 168, 190, 0.36) -6.03%, rgba(93, 107, 184, 0.00) 112.54%),#1A1A1A;
--about-bg:linear-gradient(180deg, rgba(129, 61, 255, 0.50) -35.37%, rgba(87, 134, 255, 0.50) 26.5%, rgba(99, 156, 253, 0.50) 60.2%, rgba(88, 172, 249, 0.50) 100%);
--map-bg:linear-gradient(180deg, rgba(129, 61, 255, 0.50) -35.37%, rgba(87, 134, 255, 0.50) 26.5%, rgba(99, 156, 253, 0.50) 60.2%, rgba(88, 172, 249, 0.50) 100%);
--Surface-surface-primary:#1A1A1A;
--Stroke-Clickable:#ABABAB;
--radial-partners-bg:radial-gradient(47.76% 47.76% at 33.54% 64.26%, rgba(130, 187, 254, 0.20) 0%, rgba(114, 179, 255, 0.00) 100%);
--button-partners:linear-gradient(163deg, rgba(137, 168, 190, 0.36) -6.03%, rgba(93, 107, 184, 0.00) 112.54%), #1A1A1A;
--partners-bg:linear-gradient(180deg, rgba(129, 61, 255, 0.50) -35.37%, rgba(87, 134, 255, 0.50) 26.5%, rgba(99, 156, 253, 0.50) 60.2%, rgba(88, 172, 249, 0.50) 100%);
--projects_one_bg:linear-gradient(180deg, #813DFF -35.37%, #5786FF 26.5%, #639CFD 60.2%, #58ACF9 100%);
--projects_one_bg_small:linear-gradient(180deg, rgba(129, 61, 255, 0.50) -35.37%, rgba(87, 134, 255, 0.50) 26.5%, rgba(99, 156, 253, 0.50) 60.2%, rgba(88, 172, 249, 0.50) 100%);
}
/*end of colors*/
@mixin return_font($family,$size,$line-height,$color)
{
  font: {
     @if $family!='' {family: $family;}
     @if $size!='' {    size: #{$size};}
  		}
     @if $color!='' {color:#{$color};}
     @if $line-height!='' {line-height:#{$line-height};}
}